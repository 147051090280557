.breadcrumbs {
	font-size: $font-size-small;
	margin-bottom: $base-line;

    & ul {
        @include flex;
        @include flex-items-middle;
    }
    &.push-center ul {
        @include flex-items-center;
    }
    & span,
    & a {
    	font-style: normal;
    	padding: 0 10px;
    	display: inline-block;
    	white-space: nowrap;
    }
    & li {
        &:after {
        	display: inline-block;
        	content: '/';
        	color: rgba(0, 0, 0, .3);
        }
        &:last-child:after {
        	display: none;
        }
        &:first-child span,
        &:first-child a {
            padding-left: 0;
        }
        &.active a {
            color: $color-text;
        	text-decoration: none;
        	cursor: text;
        }
    }
}
