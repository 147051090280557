.bank_cards {
  position: relative;

  &.disabled {
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.7);
    }

    .bank_card:hover {
      box-shadow: none;
      cursor: default;
      &:before { display: block; }
    }
  }

  .header {
    padding: 0.875rem 0 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: middle;
    padding: 0.875rem 0;

    .bank_card + .bank_card {
      margin-left: 0.875rem;
    }
  }
}

.bank_card {
  position: relative;
  width: 5rem;
  height: 3rem;
  border: 1px solid #DCD8D2;
  border-radius: 0.2rem;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  display: flex;
    justify-content: center;
    align-items: center;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .icon {
    font-size: 2.4rem;
  }

  &:hover {
    box-shadow: 0 0 1rem #dedede;

    &:before {
      display: none;
    }
  }

  &.active {
    &:before {
      display: none;
    }
  }
}
