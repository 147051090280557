.quotas-form {
  position: relative;
  padding: 1.125rem 1rem;

  &__header {

  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;

    .fields {
      display: flex;
      align-items: center;
      justify-content: space-between;
      user-select: none;

      .field {
        input[type=text] {
          width: 3rem;
        }
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(calc(100% + 0.375rem));

    .buttons {
      .button + .button {
        margin-left: 0.25rem;
      }
    }
  }

  &__header + &__body {
    margin-top: 1rem;
  }
}
