@keyframes slideUp {
    to { height: 0; padding-top: 0; padding-bottom: 0; }
}
@keyframes slideDown {
    from { height: 0;  padding-top: 0; padding-bottom: 0; }
}
@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@keyframes fadeOut {
    from { opacity: 1; }
    to   { opacity: 0; }
}
@keyframes flipIn {
    from { opacity: 0; transform: scaleY(0); }
    to   { opacity: 1; transform: scaleY(1); }
}
@keyframes flipOut {
    from { opacity: 1; transform: scaleY(1); }
    to   { opacity: 0; transform: scaleY(0); }
}
@keyframes zoomIn {
	from { opacity: 0; transform: scale3d(.3, .3, .3); }
	50%  { opacity: 1; }
}
@keyframes zoomOut {
	from { opacity: 1; }
	50%  { opacity: 0; transform: scale3d(.3, .3, .3); }
	to   { opacity: 0; }
}
@keyframes slideInRight {
	from { transform: translate3d(100%, 0, 0); visibility: visible; }
	to   { transform: translate3d(0, 0, 0); }
}
@keyframes slideInLeft {
	from { transform: translate3d(-100%, 0, 0); visibility: visible; }
	to   { transform: translate3d(0, 0, 0); }
}
@keyframes slideInDown {
	from { transform: translate3d(0, -100%, 0); visibility: visible; }
	to   { transform: translate3d(0, 0, 0); }
}
@keyframes slideOutLeft {
    from { transform: translate3d(0, 0, 0); }
    to   { visibility: hidden; transform: translate3d(-100%, 0, 0); }
}
@keyframes slideOutRight {
	from { transform: translate3d(0, 0, 0); }
	to   { visibility: hidden; transform: translate3d(100%, 0, 0); }
}
@keyframes slideOutUp {
	from { transform: translate3d(0, 0, 0); }
	to   { visibility: hidden; transform: translate3d(0, -100%, 0); }
}
@keyframes rotate {
	from { transform: rotate(0deg);   }
	to   { transform: rotate(360deg); }
}
@keyframes pulse {
	from { transform: scale3d(1, 1, 1); }
	50%  { transform: scale3d(1.03, 1.03, 1.03); }
	to   { transform: scale3d(1, 1, 1); }
}
@keyframes shake {
    15% { transform: translateX(0.5rem); }
    30% { transform: translateX(-0.4rem); }
    45% { transform: translateX(0.3rem); }
    60% { transform: translateX(-0.2rem); }
    75% { transform: translateX(0.1rem); }
    90% { transform: translateX(0); }
    90% { transform: translateX(0); }
}

.fadeIn        { animation: fadeIn 250ms; }
.fadeOut       { animation: fadeOut 250ms; }

.zoomIn        { animation: zoomIn 200ms; }
.zoomOut       { animation: zoomOut 500ms; }

.slideInRight  { animation: slideInRight 500ms; }
.slideInLeft   { animation: slideInLeft 500ms; }
.slideInDown   { animation: slideInDown 500ms; }
.slideOutLeft  { animation: slideOutLeft 500ms; }
.slideOutRight { animation: slideOutRight 500ms; }
.slideOutUp    { animation: slideOutUp 500ms; }

.slideUp       { overflow: hidden; animation: slideUp 200ms ease-in-out; }
.slideDown     { overflow: hidden; animation: slideDown 80ms ease-in-out; }

.flipIn        { animation: flipIn 250ms cubic-bezier(0.5, -0.5, 0.5, 1.5) }
.flipOut       { animation: flipOut 500ms cubic-bezier(0.5, -0.5, 0.5, 1.5) }

.rotate        { animation: rotate 500ms; }
.pulse         { animation: pulse 250ms 2; }
.shake         { animation: shake 500ms; }