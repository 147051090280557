$input-height: $react-dates-input-line-height + $react-dates-input-padding * 2 + $react-dates-input-displaytext-padding-vertical * 2;
$caret-top-down: $react-dates-spacing-vertical-picker - $react-dates-width-tooltip-arrow / 2;
$caret-top-up: $input-height - $react-dates-spacing-vertical-picker;

.DateInput {
  // font
  font-weight: normal;
  font-size: $react-dates-input-font-size;
  line-height: $react-dates-input-line-height;
  color: $react-dates-color-placeholder-text;
  margin: 0;
  padding: $react-dates-input-padding;

  // background: $react-dates-color-white;
  position: relative;
  display: inline-block;
  width: $react-dates-width-input;
  vertical-align: middle;
}

.DateInput--with-caret::before,
.DateInput--with-caret::after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: auto;
  border: $react-dates-width-tooltip-arrow / 2 solid transparent;
  left: 58px;
  z-index: $react-dates-z-index + 2;
}

.DateInput--open-down.DateInput--with-caret::before,
.DateInput--open-down.DateInput--with-caret::after {
  border-top: 0;
}

.DateInput--open-down.DateInput--with-caret::before {
  top: $caret-top-down;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.DateInput--open-down.DateInput--with-caret::after {
  top: $caret-top-down + 1;
  border-bottom-color: $react-dates-color-white;
}

.DateInput--open-up.DateInput--with-caret::before,
.DateInput--open-up.DateInput--with-caret::after {
  border-bottom: 0;
}

.DateInput--open-up.DateInput--with-caret::before {
  top: $caret-top-up;
  border-top-color: rgba(0, 0, 0, 0.1);
}

.DateInput--open-up.DateInput--with-caret::after {
  top: $caret-top-up - 1;
  border-top-color: $react-dates-color-white;
}

.DateInput--disabled {
  background: $react-dates-color-gray-lighter;
}

.DateInput__input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;

  &:disabled {
    opacity: inherit;
    background: inherit;
    font-style: inherit;
    color: inherit;
    text-align: inherit;
  }

  &[readonly] {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.DateInput__display-text {
  padding: $react-dates-input-displaytext-padding-vertical $react-dates-input-displaytext-padding-horizontal;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  min-height: 1.375rem;
}

.DateInput__display-text--has-input {
  color: inherit;
}

.DateInput__display-text--focused {
  // background: $react-dates-color-focus;
  // border-color: $react-dates-color-focus;
  // border-radius: 2px;
  color: inherit;
  font-weight: bold;
}

.DateInput__display-text--disabled {
  font-style: regular;
}
