@import "./page";
@import "./menu";

.layout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;

  & > div:nth-child(2) {
    max-width: calc(100vw - 16rem);
  }
}
