.dropdown {
	position: absolute;
	z-index: $z-over-content;
	top: 0;
	right: 0;
	width: 280px;
	color: #000;
	font-size: $font-size - 1px;
	background: #fff;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .15);
    border-radius: 3px;
	max-height: 300px;
	margin: 0;
	padding: 0;
    overflow: hidden;

    &.dropdown-mobile {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-height: none;
        border: none;
    }
    & .close {
        margin: 20px auto;
    }
    &.open {
        overflow: auto;
    }
    & ul {

    	list-style: none;
    	margin: 0;

    	& li {
    		border-bottom: 1px solid rgba(0, 0, 0, .07);
    		&:last-child {
    			border-bottom: none;
    		}
    	}
    	& a {
    		display: block;
    		padding: 12px;
    		text-decoration: none;
    		color: #000;
    		&:hover {
    			background: rgba(0, 0, 0, .05);
    		}

    	}
    }
}