.dashboard {
  position: relative;
  width: 100%;
  height: calc(100vh - #{$topbar-height});
  overflow: hidden;
  padding-left: $dash-ctrl-width--left;
  padding-top: $dash-ctrl-height;
  background-color: $dash-bg-color;
  z-index: 9;
  box-sizing: border-box;

  .dashboard__wrapper {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    overflow: auto;
  }
}
