.map {
  position: relative;
  width: 100%;
  height: 12rem;
  border: 1px solid $color-lightgray;
  border-radius: 0.25rem;

  & > div {
    width: 100%;
    height: 100%;
  }

  i.icon {
    font-size: 2rem;
    color: #FF0000;
  }
}
