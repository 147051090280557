.dashboard__groups {
  position: relative;
  width: calc(100% + #{$dash-ctrl-width});
  left: 0;
  z-index: 3;
  margin-left: -1*$dash-ctrl-width--left;
  overflow: hidden;

  &:empty { border-top: 1px solid #ccc; }

  .dashboard__group {
    width: 100%;
    border-top: 1px solid #ccc;

    &:last-child { border-bottom: 1px solid #ccc; }

    .dashboard__group-header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      height: 3.375rem;
      padding: 0 1.25rem;
      background-color: #FFF;
      border-bottom: 1px solid #ccc;

      .content {}

      .actions {}
    }

    .dashboard__group-content {
      padding: 0.75rem 0 1.5rem;

      .dashboard__group-item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 2rem;
        margin-top: 0.75rem;

        .dashboard__group-item--header {
          width: $dash-ctrl-width;
          background-color: #f3f3f3;
          height: 100%;
          z-index: 2;

          .content {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            height: inherit;
            padding: 0 0.75rem;

            & > div:first-child {
              min-width: 1.5rem;
            }
          }

          span.floor {
            display: inline-block;
            padding: 0.125rem 0.375rem;
            font-size: 0.75rem;
            line-height: 1rem;
            border-radius: 0.25rem;
            background-color: #D2D1CC;
          }
        }

        .dashboard__group-item--events {
          flex-grow: 1;
          z-index: 1;

          .content {
            position: relative;
            float: left;
            width: auto;
            height: 2rem;

            .event {
              position: absolute;
              width: 10rem;
              height: inherit;
              line-height: 2rem;
              top: 0;
              background-color: #8CD788;
              border-radius: 0.25rem;
              padding: 0 0.875rem;
              vertical-align: middle;
              cursor: pointer;
              color: #333;
              text-decoration: none;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              &:hover { background-color: #83CE7F; }

              &.cian {
                @extend .event;
                background-color: #92D6D0;

                &:hover { background-color: #83C8C1; }
              }

              &.active {
                background-color: #3B4149;
                color: #fff;

                &:hover { background-color: #3B4149; }
              }
            }
          }
        }
      }
    }
  }
}
