.form-toggle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  &__item {
    cursor: pointer;
    border: 1px solid #d4d4d4;
    border-radius: 6px;
    padding: 0.5rem 0.875rem;
    @include transition;

    &:hover, &.active {
      border-color: #d4d4d4;
      box-shadow: inset 0 0 0.25rem #d4d4d4;
      background-color: lighten(#d4d4d4, 10%);

      & > .description {
        color: darken(#bdbdbd, 25%);
      }
    }

    & > .header {
      font-weight: bold;
      margin-bottom: 0.125rem;
    }

    & > .description {
      color: #bdbdbd;
      font-size: 0.75rem;
      line-height: 1rem;
      @include transition;
    }
  }

  &__item + &__item {
    margin-left: 0.875rem;
  }

  &.padded {
    margin-bottom: 1.875rem;
  }
}
