.react-tel-input {
  .form-control {
    font-size: 14px;
    border-radius: 6px;
    height: 32px;
    width: 100%;
  }
}

.form_input_phone {
  height: 32px !important;
}
