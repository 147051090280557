.quotas {
  display: relative;
  width: 100%;

  .quotas__header {
    width: 100%;
    color: #999;
    margin-bottom: 1rem;

    &.bordered {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #D7D3CC;
    }
  }

  .quotas__footer {
    padding-bottom: 1.85rem;
  }

  .quotas__items {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;

    & > .quotas__item {
      position: relative;
      width: 100%;
      height: auto;
      margin: 0.45rem 0;
      padding-bottom: 0.45rem;
      border-bottom: 1px solid #D7D3CC;

      &.deleted {
        display: none;
      }
    }

    .quotas__item:last-child {
      border-bottom: none;
    }
  }

  .quotas__item > .content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 0.45rem;

    .quota__dates {
      width: 100 / 12 * 5%;
    }

    .quota__quantity {
      width: 100 / 12 * 6%;
      input {
        display: inline-block;
        margin-right: 0.5rem;
        width: 5.6rem;
      }
      label {
        display: inline;
      }
    }

    .quota__action {
      &-icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
        height: 1.8rem;
      }
    }
  }

}
