$rooms-item-size: 3.8rem;
$rooms-item-border: 1px solid #D1D1D1;
$rooms-item-bg-active: #3B4149;
$rooms-item-shadow: inset 0 1px 7px 0 rgba(0,0,0,0.50);
$rooms-item-font-active: #FFF;

.rooms {
  position: relative;
}

.rooms__item {
  display: inline-block;
  position: relative;
  width: percentage(1/9);
  padding: percentage(1/18) 0;
  border-left: $rooms-item-border;
  border-bottom: $rooms-item-border;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;


  &:nth-child(9n), &:last-child {
    border-right: $rooms-item-border;
  }

  &:nth-child(-n+9) {
    border-top: $rooms-item-border;
  }

  a {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0;
    padding-top: calc(50% - 1.125/2*1rem);
    padding-bottom: calc(50% - 1.125/2*1rem);
    line-height: 1.125rem;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.active, &:hover {
      color: $rooms-item-font-active;
      background-color: $rooms-item-bg-active;
      box-shadow: $rooms-item-shadow;
    }
  }
}
