@import "reset";

@mixin button-extended($color-text, $color-back) {
  @include button($color-text, $color-back);

  &:hover {
    color: $color-text;
    background-color: darken($color-back, 15%);
  }

  &.disabled {
    pointer-events: none;

    &:hover {
      color: inherit;
      background-color: inherit;
    }
  }
}

.button {
  box-sizing: border-box !important;
}

.button.green {
  @include button-extended(#fff, #41AB42);
}

.button.gray {
  @include button-extended(#333333, #D2D1CC);
}

.button.dark {
  @include button-extended(#fff, rgba(210, 209, 204, 0.1));
}

.button.red {
  @include button-extended(#fff, #FF5656);
}

.button.light-red {
  @include button-extended(#fff, #C86F6E);
}

.period_buttons {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.buttons {
  .button + .button,
  .button + a,
  a + a,
  a + .button {
    margin-left: 0.875rem;
  }
}

.link {
  text-decoration: none;
}

.button.circle {
  display: inline-block;
  position: relative;
  width: 3rem;
  height: 3rem;
  min-height: 3rem;
  padding: 0;
  border-radius: 50%;
  text-align: center;
  vertical-align: top;
  box-shadow: 0 0 1rem #dedede;

  i {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
  }
}

// Loading
.button {
  &.loading {
    position: relative;
    cursor: default;
    pointer-events: none;
    border: none;

    &:before {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      content: '';
      width: calc(100%);
      height: calc(100%);
      background-color: rgba(255,255,255, 0.8);
      border-radius: inherit;
    }

    &:after {
      position: absolute;
      content: '';
      border: 2px solid lighten($color-button-primary, 25%); /* Light grey */
      border-top: 2px solid $color-button-primary; /* Blue */
      border-radius: 50%;
      width: 1em;
      height: 1em;
      animation: spin 1s linear infinite;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: calc(50% - 0.5em);
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
