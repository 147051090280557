.price-calculation-rule {
  label {
    padding-bottom: 5px;
  }

  .price-calculation-rule__values {
    .price-calculation-rule__value {
      input {
        display: inline-block;
        margin-right: 0.2rem;
        width: 1rem;
      }
      padding-bottom: 5px;
    }
  }

  .price-calculation-rule__tips {
    color: rgba($color-text, .5);
    padding-bottom: 5px;
  }

  .price-calculation-rule__values + .price-calculation-rule__tips {
    margin-top: 0.475rem;
  }
}
