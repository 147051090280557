.priceboard__label {
  position: absolute;
  top: $dash-ctrl-height;
  left: 0;
  width: $dash-ctrl-width;
  height: calc(100vh - 9.5rem);
  box-sizing: border-box;
  z-index: 8;

  .priceboard__label--wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0rem;
  }

  .room_types {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    overflow: visible;

    & > .room-type {
      width: 100%;

      .room-type__header {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        height: 3.375rem;
        padding: 0 1.25rem;
        background-color: #FFF;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;

        & > .content {
          width: 100%;
          height: 100%;
          line-height: 3.375rem;
          white-space:nowrap;
          vertical-align: middle;
        }

        & > .actions {}
      }

    }
  }

  .room-type .tariffs {
    padding: 0.75rem 0 1.5rem;
    border: none;
    border-right: 1px solid #ccc;

    & > .tariff {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 0.75rem;
      white-space: nowrap;

      &:first-child { margin-top: 0; }

      .tariff__header {
        height: $pd-tariff-header-h;
        margin-bottom: $pd-tariff-header-offset-bt;
        padding: 0 1.25rem;
        background-color: transparent;
        z-index: 2;
        text-align: left;
        font-size: 0.75rem;

        & > .content {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-around;
          align-items: center;
          width: 100%;
          height: inherit;
          padding: 0 0.75rem;

          & > div:first-child {
            min-width: 1.5rem;
          }
        }

        span.floor {
          display: inline-block;
          padding: 0.125rem 0.375rem;
          font-size: 0.75rem;
          line-height: 1rem;
          border-radius: 0.25rem;
          background-color: #D2D1CC;
        }
      }

      .tariff__content {
        width: 100%;
      }
    }
  }

  .tariffs .rates {
    text-align: center;
    width: 100%;

    .rate {
      position: relative;
      width: 100%;

      .rate__header {
        width: 100%;
        height: 1.5rem;
        line-height: 1.5rem;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
}
