.dashboard__label {
  position: absolute;
  top: $dash-ctrl-height;
  left: 0;
  width: $dash-ctrl-width--left;
  height: calc(100vh - 9.5rem);
  box-sizing: border-box;
  z-index: 8;

  .dashboard__label--wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0rem;
  }

  .grid {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;

    & > .item {
      width: 100%;

      .item__header {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        height: 3.375rem;
        padding: 0 1.25rem;
        background-color: #FFF;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;

        & > .content {
          position: absolute;
          top: 50%;
          width: 100%;
          font-weight: bold;
          white-space: nowrap;
          vertical-align: middle;
          transform: translate(0, -50%);

          .sub_header {
            font-weight: bolder;
            color: #117723;

            span + span {
              &:before {
                content: ', ';
              }
            }

            span.alert {
              color: #ff9595;
            }
          }
        }

        & > .actions {}
      }

      .item__values {
        padding: 0.75rem 0 1.5rem;
        border-right: 1px solid #ccc;

        & > .item__value {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          height: 2rem;
          margin-top: 0.75rem;

          .value__header {
            width: $dash-ctrl-width--left;
            background-color: #f3f3f3;
            height: 100%;
            z-index: 2;

            & > .content {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: inherit;
              padding: 0 0.35rem;

              & > div:first-child {
                min-width: 1.5rem;
              }
            }

            span.floor {
              display: inline-block;
              padding: 0.125rem 0.375rem;
              font-size: 0.75rem;
              line-height: 1rem;
              border-radius: 0.25rem;
              background-color: #D2D1CC;

              &.extra {
                background-color: #EAECF0;
              }
            }
          }
        }
      }
    }
  }
}

.dashboard__label {
  .item__sources {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    align-items:  flex-start;
    align-content: center;
    box-shadow: 0 1px 0.5rem #dedede;

    .source {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: inherit;
      border-bottom: 1px solid #ccc;
      padding: 0.1875rem 1rem;
    }
  }

  .item__quotas {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    align-items:  flex-start;
    align-content: center;

    .quota {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &.actionable {
        cursor: pointer;
        user-select: none;
      }

      &.active {
        .icon svg {
          transform: rotate(180deg);
        }
      }

      & > .text {
        height: 2rem;
        line-height: 2rem;
        vertical-align: middle;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & > .icon {
        width: 1.125rem;
        height: 1.125rem;
        line-height: 1.125rem;
        border-radius: 0.25rem;
        background-color: #FBEFDB;
        color: #4C4C4C;
        text-align: center;
        vertical-align: middle;

        svg {
          @include transition;
        }
      }
    }
  }

  .item__rooms {
    padding: 0.75rem 0 1.5rem;
    border-right: 1px solid #ccc;
  }

  .item__room {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
    padding: 0.75rem 0;

    .room {
      line-height: 2rem;
      text-align: middle;
    }

    .beds {
      & > .bed {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 2rem;

        span.number {
          display: inline-block;
          padding: 0.125rem 0.375rem;
          font-size: 0.75rem;
          line-height: 1rem;
          border-radius: 0.25rem;
          background-color: #D2D1CC;

          &.extra {
            background-color: #EAECF0;
          }
        }
      }

      .bed + .bed {
        margin-top: 0.375rem;
      }
    }

    span.floor {
      display: inline-block;
      padding: 0.125rem 0.375rem;
      font-size: 0.75rem;
      line-height: 1rem;
      border-radius: 0.25rem;
      background-color: #D2D1CC;

      &.extra {
        background-color: #EAECF0;
      }
    }
  }
}
