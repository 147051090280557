.popover {
  position: relative;

  &.is_openned {
    input {
      border-bottom: 1px solid #bfbeba;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  .popover__body {
    position: absolute;
    width: 100%;
    max-height: 290px;
    background-color: #D2D1CC;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 9;
    overflow: auto;
  }
}

.popover_quantity {
  .form__field {
    margin-bottom: 0;

    input {
      background-color: #D2D1CC;
    }
  }

  .quantity__list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 12px 11px;

    .quantity__item {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      height: 34px;

      label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        // margin-bottom: 3px;
      }

      input {
        margin-top: 1px;
        margin-right: 8px;
        background-color: #C6C6C2;
      }
    }
  }
}
