$infoblock-offset: 1.875rem;
$infoblock-navigation-h: 5rem;

.infoblock {
  @include flex;
  @include flex-items-column;
  width: 100%;
  height: inherit;
}

.infoblock__header {
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding: 0.875rem 0;

  .sub-header {
    font-size: 0.875rem;
  }

  .action {
    position: absolute;
    right: 0.875rem;
    top: 0.45rem;
    height: 1.8rem;
    font-size: 1.8rem;
    line-height: 1.8rem;
    vertical-align: middle;

    a { color: #333; }
  }
}

.infoblock__navigation {
  @include flex-item-width($infoblock-navigation-h);

  & > .content {
    @include flex;
    @include flex-items-row;
    @include flex-items-nowrap;
    @include flex-items-space-between;
    @include flex-items-middle;
    height: 100%;
    padding: 0 $infoblock-offset;
    overflow: auto;
  }

  .navigation__item {
    font-size: 1.5rem;

    a {
      color: inherit;
    }

    span {
      font-size: 0.875rem;
    }
  }
}

.infoblock__body {
  @include flex-item-grow(1);
  height: calc(100% - #{$infoblock-navigation-h});

  & > .content,
  .scroll-content > div > .content {
    height: 100%;
    padding: 0 $infoblock-offset;
  }
}

.order__form {
  @include flex;
  @include flex-items-column;
  @include flex-items-space-between;
  height: 100%;

  .order__form--header {
    @include flex-item-grow(0);
    padding-bottom: 0.875rem;
  }

  .order__form--content {
    @include flex-item-grow(1);
    margin-left: -1 * $infoblock-offset;
    margin-right: -1 * $infoblock-offset;
    padding: 0 $infoblock-offset;
    overflow: auto;
  }

  .order__form--actions {
    @include flex-item-grow(0);
    padding: 0.875rem 0;
  }
}
