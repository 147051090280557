$status-size: 0.375rem;
$status-offset: 0.5rem;

span.status {
  display: inline-block;
  position: relative;
  padding-left: 2 * $status-offset + $status-size;

  &:before {
    position: absolute;
    display: inline-block;
    content: '';
    left: $status-offset;
    top: 0; bottom: 0;
    margin: auto;
    width: $status-size;
    height: $status-size;
    border-radius: 50%;
  }

  &.confirmed {
    &:before {
      background-color: #41AB42;
    }
  }

  &.booked {
    &:before {
      background-color: #1c86f2;
    }
  }

  &.cancelled {
    &:before {
      background-color: #FF5656;
    }
  }

  &.draft {
    &:before {
      background-color: #f7ba45;
    }
  }

  &.new {
    &:before {
      background-color: #D2D1CC;
    }
  }
}
