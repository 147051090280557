.dashboard__content {
  width: 100%;
  height: 100%;
  z-index: 8;
  overflow: auto;

  .dashboard__content--wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .grid {
    position: absolute;
    white-space: nowrap;
    width: auto;
    height: 100%;
    overflow: hidden;

    & > .item {
      display: inline-block;
      width: $dash-item-width;
      height: inherit;
      border-right: 1px solid #ccc;
      box-sizing: border-box;
      background-color: $dash-item-bg;

      &.day-off { background-color: $dash-item-bg-day-off; }
    }
  }

  .room_types {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100%;
    overflow: hidden;

    & > .room_type {
      position: relative;

      & > .header {
        width: 100%;
        height: 3.375rem;
        box-sizing: border-box;

        .content {
          height: 100%;
          width: 100%;
          background-color: #FFF;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
        }
      }

      & > .sources {
        display: flex;
        flex-direction: column;

        .source {
          background-color: #fff;
          padding: 0.1875rem 0;
          border-bottom: 1px solid #ccc;
        }
      }

      .quotas {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;

        .quota {
          flex-shrink: 0;
          width: 3.125rem;
          border-right: 1px solid #ccc;
          background-color: #fff;
          // user-select: none;

          & > .item {
            position: relative;
            width: 100%;
            height: 2rem;

            &.available { color: #329833; }

            &.busy { color: #FB908F; }

            &.restriction { color: inherit; }

            span.text {
              display: inline-block;
              width: 100%;
              height: 2rem;
              line-height: 2rem;
              vertical-align: middle;
              text-align: center;
              white-space: nowrap;
            }

            span.selected {
              background-color: rgba(79,160,254,0.4);
              width: calc(100% + 1px);
              border-right: 1px solid #4FA0FE;
            }

            div.badge {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;

              .count_plus {
                width: 16px;
                height: 16px;
                line-height: 16px;
                font-size: 10px;
                color: #fff;
                border-radius: 9999px;
                background: #52AB18;
                vertical-align: middle;
                text-align: center;

                &:before {
                  content: '+';
                }
              }

              .icon {
                margin-top: -4px;
                height: 11px;
              }

              .count + .icon, .count + .count_plus {
                margin-left: 4px;
              }
            }
          }
        }
      }

      & > .rooms {
        position: relative;
        padding: 0.75rem 0 1.5rem;

        .beds {
          padding: 0.75rem 0;
        }

        .beds:nth-child(even) {
          background-color: rgba(0, 0, 0, 0.1);
        }

        .events + .events {
          margin-top: 0.375rem;
        }

        .events {
          height: 2rem;

          .event {
            min-width: 1rem;
            height: inherit;
            line-height: 2rem;
            background-color: #8CD788;
            border-radius: 0.25rem;
            padding: 0 0.875rem;
            vertical-align: middle;
            cursor: pointer;
            color: #333;
            text-decoration: none;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:hover { background-color: #83CE7F; }

            &.cian {
              @extend .event;
              background-color: #92D6D0;

              &:hover { background-color: #83C8C1; }
            }

            &.active {
              background-color: #3B4149;
              color: #fff;

              &:hover { background-color: #3B4149; }
            }
          }
        }
      }
    }
  }
}
