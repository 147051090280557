.price-lists {
  @for $i from 1 through 100 {
    .w#{$i} { width: $i * 1%;  min-width: $i * 1%; }
  }
  position: relative;

  .caption {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    padding: 0.75rem 0;
    border-bottom: 1px solid #DCD8D2;

    .item.checkbox {
      width: 10%; min-width: 7%;
    };

    .item.name {
      width: 40%; min-width: 30%;
      .sub_header {
        color: #838383;
      }
    };

    .item.source {
      width: 25%; min-width: 25%;
    };

    .item.period {
      width: 25%; min-width: 25%;
    };
  }

  & > .caption {
    color: #838383;
    padding-top: 0;
  }
}

.price-lists__content {
  margin-bottom: 1.25rem;
}

.price-lists__actions {
  button, .button {
    margin-right: 1rem;
    &:last-child { margin-right: 0; }
  }
}

.price-lists__item {
  & > .caption { cursor: pointer;
    display: flex;
    align-items: center;
  }

  & > .content {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
    margin-top: -1px;
    padding: 0 1.875rem;
    border-top: 1px solid #DCD8D2;
    border-bottom: 1px solid #DCD8D2;
    box-shadow: 0 1px 0.5rem #dedede;

    .header {
      padding: 0.875rem 0;
      cursor: pointer;

      .sub_header {
        color: #838383;
      }
    }

    .description {
      width: auto;
      height: auto;

      .row { margin-top: 1rem; margin-bottom: 1rem; }
    }

    .actions {
      padding: 1.875rem 0;
      button, .button {
        margin-right: 0.75rem;
        &:last-child { margin-right: 0; }
      }
    }
  }
}
