.label {
    display: inline-block;
    font-size: 13px;
    background: $color-silver;
    line-height: 18px;
    padding: 0 10px;
    font-weight: 500;
    color: $color-text;
    border: 1px solid transparent;
    vertical-align: middle;
    text-decoration: none;
    border-radius: 4px;
	& a,
	& a:hover {
		color: inherit;
		text-decoration: none;
	}
}
.label.big {
    font-size: $font-size-small;
    line-height: 24px;
    padding: 0 12px;
}
.label.upper {
    text-transform: uppercase;
    font-size: 11px;
}

// Outline
.label.outline {
    background: none;
    border-color: $color-gray;
}

// Badges
.label.badge {
    text-align: center;
    border-radius: 64px;
    padding: 0 6px;
    &.big {
        padding: 0 8px;
    }
}

// Tag
.label.tag {
    padding: 0;
    background: none;
    border: none;
    text-transform: uppercase;
    font-size: $font-size-smaller - 1px;
    &.big {
        font-size: $font-size-small - 1px;
    }
}

.label.success {
    @include label(#fff, $color-success);
}
.label.error {
    @include label(#fff, $color-error);
}
.label.warning {
    @include label($color-black, $color-warning);
}
.label.focus {
    @include label(#fff, $color-focus);
}
.label.black {
    @include label(#fff, $color-black);
}
.label.inverted {
    @include label($color-black, $color-inverted);
}