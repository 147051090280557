$buildings-item-size: 3.5rem;
$buildings-item-border: 1px solid #D1D1D1;
$buildings-item-bg-active: #3B4149;
$buildings-item-shadow: inset 0 1px 7px 0 rgba(0,0,0,0.50);
$buildings-item-font-active: #FFF;

.buildings {
  position: relative;
  overflow: auto;
}

.buildings__item {
  position: relative;
  width: 100%;

  a {
    display: inline-block;
    width: 100%;
    height: $buildings-item-size;
    line-height: $buildings-item-size;
    color: inherit;
    text-decoration: none;
    padding: 0 1rem;

    &.active, &:hover {
      color: $buildings-item-font-active;
      background-color: $buildings-item-bg-active;
      box-shadow: $buildings-item-shadow;
    }
  }
}

.buildings__item + .buildings__item {
  border-top: $buildings-item-border;
}
