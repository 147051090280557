@media print {

    * {
        background: transparent !important;
        color: black !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    pre, blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    p, h2, h3 {
        orphans: 3;
        widows: 3;
    }

    thead { display: table-header-group; }
    tr, img { page-break-inside: avoid; }
    img { max-width: 100% !important; }
    h2, h3, h4 { page-break-after: avoid; }
    @page { margin: 0.5cm; }

}
