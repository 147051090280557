.Collapsible {
  position: relative;
  width: 100%;

  .Collapsible__trigger {
    &.is-open {
      .Collapsible__header {
        font-weight: bold;
        .icon { transform: translate(0, -50%) rotate(180deg); }
      }
    }
  }

  .Collapsible__header {
    position: relative;
    width: 100%;
    padding: 0.45rem 2rem 0.45rem 0;
    user-select: none;
    cursor: pointer;

    .sub-header {
      color: #999;
      font-weight: normal;
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 0;
      font-size: 1.5rem;
      transform: translate(0, -50%);
    }
  }

  .Collapsible__contentInner {
    padding: 1rem 0;
  }
}

.Collapsible + .Collapsible {
  border-top: 1px solid #EAE9E4;
}
