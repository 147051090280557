.periods {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  .periods__header {
    width: 100%;
    color: #999;
    margin-bottom: 1rem;

    &.bordered {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #D7D3CC;
    }
  }

  .periods__wrapper {
    flex: 1;
  }

  .periods__items {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;

    & > .periods__item {
      position: relative;
      width: 100%;
      height: auto;

      &.deleted {
        display: none;
      }
    }

    .periods__item + .periods__item {
      margin-top: 0.875rem;
    }
  }

  .periods__item > .content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;

    .period__dates {
      width: 100 / 12 * 5%;
    }

    .period__prices {
      width: 100 / 12 * 6%;
    }
  }

  .periods__item > .control {
    position: relative;
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid #D7D3CC;
  }
}
