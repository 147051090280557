$collapse-border-color: #DCD8D2;
$collapse-border-width: 1px;

$collapse-item-header-height: 3rem;
$collapse-item-header-color: #333333;
$collapse-item-offset: 1rem;
$collapse-item-shadow: 0 1px 0.5rem #dedede;

.collapse {
  width: 100%;
  border: $collapse-border-width solid $collapse-border-color;
  border-bottom: none;
  box-sizing: border-box;

  &:empty { border: none; }
}

.collapse__item {
  position: relative;
  border-bottom: $collapse-border-width solid $collapse-border-color;

  &.active {
    box-shadow: $collapse-item-shadow;

    .collapse__item-header { font-weight: bold; }
  }
}

.collapse__item-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: $collapse-item-header-height;
  line-height: $collapse-item-header-height;
  padding: 0 $collapse-item-offset;
  cursor: pointer;
  user-select: none;

  .description {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .price {
    min-width: 8rem;
    text-align: right;
  }
}

.collapse__item-content {
  padding: 0 $collapse-item-offset $collapse-item-offset;

  & > .options {
    margin-bottom: 1rem;

    ul.list {
      list-style: none;
      margin-left: 0;
    }
  }

  & > .actions {
    margin-bottom: 1rem;

    .button {
      margin-right: 0.5rem;
    }
  }
}
