@import "variables";

.screen-reader-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.DateRangePicker {
  position: relative;
  display: inline-block;
  width: 100%;
}

.DateRangePicker__picker {
  z-index: $react-dates-z-index + 1;
  background-color: $react-dates-color-white;
  position: absolute;
}

.DateRangePicker__picker--rtl {
  direction: rtl;
}

.DateRangePicker__picker--direction-left {
  left: 0;
}

.DateRangePicker__picker--direction-right {
  right: 0;
}

.DateRangePicker__picker--open-down {
  top: $react-dates-spacing-vertical-picker;
}

.DateRangePicker__picker--open-up {
  bottom: $react-dates-spacing-vertical-picker;
}

.DateRangePicker__picker--portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.DateRangePicker__picker--full-screen-portal {
  background-color: $react-dates-color-white;
}

.DateRangePicker__close {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: $react-dates-z-index + 2;

  svg {
    height: 15px;
    width: 15px;
    fill: $react-dates-color-gray-lighter;
  }

  &:hover,
  &:focus {
    color: darken(#cacccd, 10%);
    text-decoration: none;
  }
}
