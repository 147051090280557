$segment-offset: 1.875rem 1.875rem 2.5rem;
$segment-offset-star: 1.875rem 1.875rem 2rem;
$segment-border: 1px solid #DCD8D2;
$segment-shadow: 0 1px 0.5rem #dedede;

.segment {
  position: relative;
  width: inherit;
  height: inherit;
  padding: $segment-offset;
  border: $segment-border;
  box-sizing: border-box;
  background-color: #FFF;

  &.shadow {
    box-shadow: $segment-shadow;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9;
  }
}

.radio_list_item {
  display: flex;
  margin-bottom: 7px;
  align-items: center;
  width: fit-content;
}

.radio_list_item_last {
  cursor:default;
  width: fit-content;
}

.radio_button_name {
  margin-left: 10px;
  cursor:default;
}

.radio_list_star {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.segment_star {
  position: relative;
  width: inherit;
  height: inherit;
  padding: $segment-offset-star;
  border: $segment-border;
  box-sizing: border-box;
  background-color: #FFF;

  &.shadow {
    box-shadow: $segment-shadow;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9;
  }
}

.form__field--title {
  margin-bottom: 20px;
}

.form__field--title--meal {
  margin-top: 20px;
  margin-bottom: 20px;
}

.content_hotel {
  margin-bottom: 15px;
}

.value_star {
  display: flex;
  align-items: center;
}

.star__icon {
  width: 18px;
  height: 18px;
}

.form__field--title--show {
  margin-top: 20px;
}

.key {
  color: #999999;
  margin-bottom: 15px;
}