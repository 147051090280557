$dash-item-width: 3.125rem;
$dash-ctrl-width: 5rem;
$dash-ctrl-height: 4.5rem;

$dash-bg-color: #f3f3f3;
$dash-item-bg: #f3f3f3;
$dash-item-bg-day-off: #e9e9e9;

$pd-type-header-h: 3.375rem;

$pd-overflow-bg-color: rgba(0, 0, 0, 0.43);

$pd-tariff-header-h: 1rem;
$pd-tariff-header-offset-bt: 0.5rem;

$pd-tariff-values-offset-top: $pd-tariff-header-h + $pd-tariff-header-offset-bt;
$pd-tariff-values-offset-left: 0;
$pd-tariff-values-h: 1.5rem;
