.loader {
  width: 100%;
  height: 100%;
  min-height: 5.25rem;

  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    font-size: 1.25rem;
    line-height: 1.5rem;
    width: 50%;
    text-align: center;
    color: #999999;
  }
}

