.modal-box {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: $z-over-control;
}
.modal {
	position: relative;
	margin: auto;
	margin-top: 16px;
	padding: 0;
	background: #fff;
	box-shadow: 0 10px 25px rgba(0, 0, 0, .15);
    border-radius: 8px;
	color: #000;

	& input,
	& textarea {
		@media (max-width: $sm) {
		    font-size: 16px;
		}
	}
    & .close {
    	position: absolute;
    	top: 18px;
    	right: 16px;
    	opacity: .3;
    	&:hover {
        	opacity: 1;
    	}
    }
}
.modal-header {
	padding: 24px 32px;
	font-size: 18px;
	font-weight: bold;
	border-bottom: 1px solid rgba(0, 0, 0, .05);
    &:empty {
        display: none;
    }
}
.modal-body {
    padding: 36px 56px;
}



// Responsive
@media (max-width: $sm) {
    .modal-header,
    .modal-body {
        padding: 24px;
    }
}