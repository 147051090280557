$dash-item-width: 3.125rem;
$dash-ctrl-width--left: 16.5rem;
$dash-ctrl-width--right: 5.5rem;
$dash-ctrl-width: 5.5rem;
$dash-ctrl-height: 4.5rem;

$dash-bg-color: #f3f3f3;
$dash-item-bg: #f3f3f3;
$dash-item-bg-day-off: #e9e9e9;

$pd-type-header-h: 3.375rem;

$pd-overflow-bg-color: rgba(0, 0, 0, 0.43);
