.stay-period {
  padding-bottom: 20px;

  .stay-period__values {
    input {
      display: inline-block;
      margin-right: 0.475rem;
      width: 5.6rem;
    }
  }

  .stay-period__tips {
    color: rgba($color-text, .5);
  }

  .stay-period__values + .stay-period__tips {
    margin-top: 0.475rem;
  }
}
