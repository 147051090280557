$conv-header-h: 4rem;
$conv-border-color: #DCD8D2;

.conversations {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  // border: 1px solid #DCD8D2;
  // box-shadow: inset 0 0 0.5rem rgba(0,0,0,0.13);
  box-sizing: border-box;

  .conversations__header {
    flex-grow: 1;
    border-bottom: 1px solid $conv-border-color;
    box-shadow: 0 0 0.2rem rgba(0,0,0,0.13);

    .content {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0.875rem;
      font-size: 1.25rem;
    }
  }

  .conversations__tools {
    flex-grow: 1;
    border-top: 1px solid $conv-border-color;
    box-shadow: 0 0 0.2rem rgba(0,0,0,0.13);

    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0.875rem;

      .message__input {
        flex-grow: 1;
        margin-right: 0.875rem;
      }

      .message__send {}
    }
  }

  .conversations__body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: inset 0 0 0.2rem rgba(0,0,0,0.13);

    .messages {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0.875rem;

      .msg {
        position: relative;
        width: 80%;
        padding: 0.875rem;
        border: 1px solid #DCD8D2;

        .msg__header {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 0.45rem;
          color: #999999;
        }

        .msg__body {
          p {
            margin: 0;
            padding: 0;
          }
        }

        .msg__actions {
          position: absolute;
          top: 0;
          right: -1.275rem;
          font-size: 1rem;

          a {
            color: #999;
            &:hover { color: #333; }
          }
        }

        &.from {
          align-self: flex-start;
          margin-right: 1rem;
          border-radius: 0.875rem 0.875rem 0.875rem 0;
        }

        &.to {
          align-self: flex-end;
          background-color: #EAE9E4;
          border-radius: 0.875rem 0.875rem 0 0.875rem;
        }
      }

      .msg + .msg { margin-top: 0.875rem; }
    }
  }
}
