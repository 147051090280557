.dashboard__header {
  position: absolute;
  width: 100%;
  height: $dash-ctrl-height;
  top: 0;
  left: 0;
  padding-left: $dash-ctrl-width--left;
  padding-right: $dash-ctrl-width--right;
  z-index: 9;
  box-shadow: 0 0 1px #ccc;

  .dashboard__header--wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .dashboard__header--grid {
    display: inline-flex;
    position: absolute;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: auto;
    height: inherit;
    top: 0;
    left: 0;
    background-color: #FFF;

    & > .item {
      position: relative;
      flex: 0 0 $dash-item-width;
      height: $dash-ctrl-height;
      box-sizing: border-box;
      overflow: visible;

      &.day-off {
        background-color: $dash-item-bg-day-off;

        .header {
          background-color: $dash-item-bg-day-off;
          span.day { color: #ff9595; }
        }
      }

      &.week-start {}

      &.week-end {}
    }


    .header {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: $dash-ctrl-height/2;
      border-right: 1px solid #ccc;

      .header__label {
        width: 100%;
        height: inherit;
        text-align: center;
        vertical-align: middle;
      }

      .header__value {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: stretch;
        width: 100%;
        z-index: 1;

        span {
          display: inline-block;
          width: 100%;
          text-align: center;
        }

        span.day {
          font-size: 0.75rem;
          color: #999999;
        }

        .month {
          position: absolute;
          top: -$dash-ctrl-height/2;
          left: 0;
          z-index: 1;
          line-height: $dash-ctrl-height/2;
          padding-left: 1rem;
          text-transform: capitalize;
          vertical-align: middle;
          color: #999999;
        }
      }
    }
  }

  .ctrl {
    position: absolute;
    top: 0;
    height: $dash-ctrl-height;
    box-sizing: border-box;
    z-index: 2;

    &.left {
      left: 0;
      width: $dash-ctrl-width--left;
      height: 100%;
      border-right: 1px solid #ccc;
    }

    &.right {
      right: 0;
      width: $dash-ctrl-width--right;
      border-left: 1px solid #ccc;
    }

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      line-height: $dash-ctrl-height;
      text-align: center;
      vertical-align: middle;
      text-decoration: none;
      background-color: #FFF;
      color: #000;
      font-size: 2rem;

      &:hover {
        background-color: darken(#fff, 15%);
      }
    }
  }
}
