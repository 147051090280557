.seasons {

  .seasons_wrapper {
    display: flex;
    justify-content: space-between;

    .showAll {
      color: #1F6FCC;
      cursor: pointer;
    }
  }

  .seasons__caption {
    color: #999;
    line-height: 1.4rem;
  }

  .seasons__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;

    .cell {
      padding-bottom: 0.875rem;
    }
  }

  .seasons__prices {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    padding-bottom: 15px;
  }

  .cell {
    padding: 0;

    &:first-child { padding-left: 0; }
    &:last-child { padding-right: 0; }
  }

  .dates {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    line-height: 1.4rem;
    width: 170px;
    margin-right: 10px;

    .dates__item {
      white-space: nowrap;
      text-align: right;
    }

    .dates__item + .dates__item {
      margin-top: 0.2rem;
    }
  }
}
