$menu-bg-color: #3B4149;
$menu-font-color: #FFFFFF;
$menu-width: 16rem;

$menu-header-height: 5rem;
$menu-header-offset: 1.25rem;

$menu-footer-height: 6.5rem;

.layout__menu {
  position: relative;
  flex-grow: 0;
  width: $menu-width;
  min-width: $menu-width;
  color: $menu-font-color;
  background-color: $menu-bg-color;

  .layout__menu-wrapper {
    position: fixed;
    width:inherit;
    height: 100vh;
    min-height: 26rem;
  }
}

.layout-menu__header {
  position: relative;
  width: 100%;
  height: $menu-header-height;
  padding: 0 $menu-header-offset;
  line-height: $menu-header-height;
  overflow: hidden;
  text-transform: uppercase;
  vertical-align: middle;

  a {
    position: absolute;
    display: inline-block;
    width: 3rem;
    height: $menu-header-height;
    line-height: $menu-header-height;
    right: 0;
    top: 0;
    font-size: 1.2rem;
    color: inherit;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;

    &:hover {
      background-color: #2F343A;
    }
  }
}

.layout__menu-content {
  width: 100%;
  height: calc(100vh - #{$menu-header-height} - #{$menu-footer-height});
  overflow: auto;
}

.language__control-content {
  padding-left: 2.5rem;
}

.room_type__language {
  box-sizing: border-box;
  display: flex;
  align-items: center;

    .description {
      margin-bottom: 0.875rem;
    }

    .control {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__language {
        background-color: white;
        justify-content: center;
        border-radius: 4px;
        display: flex;
        align-items: center;
        border: 1px solid #DCD8D2;

        a {
          display: inline-block;
          cursor: pointer;
          color: #999;
          text-transform: uppercase;
          @include transition;

          &:hover {
            color: #333333;
          }

          &.active {
            color: #FFF;
            background-color: #52AB18;
            width: 50px;
            height: 30px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 3px;
          }

          &.item {
            width: 50px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 3px;
          }
        }
      }
    }
}

.layout__language-action {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;

    .description {
      margin-bottom: 0.875rem;
    }

    .control {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__language {
        background-color: white;
        justify-content: center;
        border-radius: 4px;
        display: flex;
        align-items: center;
        border: 1px solid #DCD8D2;

        a {
          display: inline-block;
          cursor: pointer;
          color: #999;
          text-transform: uppercase;
          @include transition;

          &:hover {
            color: #333333;
          }

          &.active {
            color: #FFF;
            background-color: #52AB18;
            width: 50px;
            height: 30px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 3px;
          }

          &.item {
            width: 50px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 3px;
          }
        }
      }
    }
}

.layout__menu-action {
  width: 100%;
  height: $menu-footer-height;
  box-sizing: border-box;

  .content {
    padding: 1.25rem;

    .description {
      margin-bottom: 0.875rem;
    }

    .control {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__exit {

      }

      &__language {
        a {
          display: inline-block;
          cursor: pointer;
          color: #999;
          text-transform: uppercase;
          @include transition;

          &:hover, &.active {
            color: #FFF;
          }
        }

        a + a {
          &:before {
            display: inline-block;
            content: "|";
            color: #999;
            font-weight: normal;
            margin: 0 0.5rem;
          }
        }
      }
    }
  }
}
