.layout__page-content {
  position: relative;
  width: inherit;
  height: calc(100% - 8rem);
}

.page__header {
  width: inherit;
  padding: 1.875rem 2.5rem 0;
  box-sizing: content-box;
}

.page__caption {
  width: inherit;
  padding: 0.875rem 0;
  font-size: 1.25rem;
  box-sizing: content-box;
}

.page__body {
  width: 37.5rem;
  min-height: 100%;
  padding: 0.2rem 2.5rem;
  box-sizing: border-box;

  &.wide {
    width: 53.5rem;
    margin-bottom: calc(100% - 85%);
  }

  &.fluid {
    width: 100%;
    min-width: 53.5rem;
  }

  &.white {
    background-color: #FFF;
  }
}

.page__info {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30rem;
  height: 100vh;
  background-color: #FFF;
  border-left: 1px solid #ddd;
  box-shadow: 0px 0px 1.5rem 0 #CFCFCF;
  z-index: 10;

  .conversations {
    height: inherit;
  }

  .page__info-wrapper {
    width: 100%;
    height: 100%;
    overflow: visible;

    & > .loader {
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      background-color: rgba(255, 255, 255, 0.5);
    }

    &.extended {
      width: calc(100% + 8rem);
      padding-left: 8rem;
      margin-left: -8rem;
    }

    .info__content {
      display: flex;
      flex-direction: column;
      width: inherit;
      height: inherit;
      padding: 1.875rem;

      &.fluid {
        padding: 0;

        & > .navigation {
          padding: 1.875rem;
        }
      }

      & > .loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        background-color: rgba(255, 255, 255, 0.5);
      }

      & > .navigation {
        @include flex-item-width(2.5rem);

        .content {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          height: 100%;
        }

        .navigation__item {
          font-size: 1.25rem;
          line-height: 1.5rem;

          a {
            color: inherit;
          }
        }
      }

      & > .header {
        font-size: 1.25rem;
        line-height: 1.5rem;
        padding: 0.875rem 0;

        .order-status {
          float: right;
        }

        .sub-header {
          font-size: 0.875rem;
        }

        .action {
          position: absolute;
          right: 0.875rem;
          top: 0.45rem;
          height: 1.8rem;
          font-size: 1.8rem;
          line-height: 1.8rem;
          vertical-align: middle;

          a { color: #333; }
        }
      }

      .content {
        //
      }

      .actions {
        margin-top: 1.5rem;

        button, .button {
          margin-right: 0.5rem;
          &:last-child { margin-right: 0; }
        }
      }

      & > .info__floating {
        position: fixed;
        bottom: 1.75rem;
        right: 2.5rem;
      }
    }
  }

  &.wide {
    width: 25rem;

    .page__info-wrapper .info__content {
      padding: 1.875rem 2.5rem;
    }
  }

  &.very.wide {
    width: 30rem;
  }

  &.w42.wide {
    width: 42rem;
  }

  &.sticked {
    top: 0;
    min-height: 0;
    height: calc(100vh - 5rem);
  }
}
