@mixin breakpoint($min: 0, $max: 0) {

    $type: type-of($min);

    @if $type == string
    {
        @if $min == sm
        {
            @media (max-width: $sm) { @content; }
        }
        @else if $min == md
        {
            @media (min-width: $sm) and (max-width: $md) { @content; }
        }
        @else if $min == lg
        {
            @media (min-width: $lg) { @content; }
        }
    }
    @else if $type == number
    {
        $query: "all" !default;
        @if $min != 0 and $max != 0 { $query: "(min-width: #{$min}) and (max-width: #{$max})"; }
        @else if $min != 0 and $max == 0 { $query: "(min-width: #{$min})"; }
        @else if $min == 0 and $max != 0 { $query: "(max-width: #{$max})"; }
        @media #{$query} { @content; }
    }
}