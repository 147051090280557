.pagination {
    margin: $base-line 0;
    font-size: $font-size-small;


    & ul {
        @include flex;

        margin: 0;
    }
    &.align-center  ul {
        @include flex-items-center;
    }
    & span,
    & a {
        border-radius: 3px;
        display: inline-block;
    	padding: 8px 12px;
    	line-height: 1;
    	white-space: nowrap;
    	border: 1px solid transparent;
    }
    & a {
    	text-decoration: none;
    	color: $color-text;
    	&:hover {
    		color: rgba(0, 0, 0, .5);
    		border-color: $color-silver;
    	}
    }
    & span,
    & li.active a {
    	color: rgba(0, 0, 0, .5);
    	border-color: $color-silver;
    	cursor: text;
    }
    &.upper {
    	font-size: $font-size-smaller;
    }
}

// Pager
.pager {
    & span {
        line-height: $base-line;
    }
    & span,
    & a {
        padding-left: 16px;
        padding-right: 16px;
	    border-radius: 64px;
        border-color: rgba(0, 0, 0, .1);
    }
    & li {
        @include flex-basis(50%);
    }
    & li.next {
	    text-align: right;
    }
    &.align-center li {
        @include flex-basis(auto);
        margin-left: 4px;
        margin-right: 4px;
    }
    &.flat {
        & span,
        & a {
            border: none;
            display: block;
            padding: 0;
        }
        & a {
            font-weight: bold;
            &:hover {
                background: none;
                text-decoration: underline;
            }
        }
    }
}

// Responsive
@include breakpoint(sm) {
    .pager.flat {
        & ul {
            @include flex-items-column;
        }
        & li {
            @include flex-basis(100%);

            margin-bottom: 8px;
            text-align: left;
        }
    }
}