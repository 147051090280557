.policies {
  .policies__row {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    padding: 0.625rem 0;

    &.header {
      color: #999;
      line-height: 2rem;
    }
  }

  .policy__content {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
  }

  .policies__number {
    position: absolute;
    top: 0.625rem;
    left: -1.875rem;
    width: 1.875rem;
    text-align: center;
    color: #999;
  }

  .column_with_download {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .policies__column {
    flex-grow: 1;
    flex-shrink: 0;

    &.title {
      display: flex;
      flex-direction: column;
      width: 60%;

      span + span {
        margin-top: 0.275rem;
      }
    }

    &.document {
      min-width: 20%;
      text-align: right;
    }

    &.action {
      width: 5%;
    }

    .person {
      display: flex;
      flex-direction: column;
      line-height: 1.125rem;
      overflow: hidden;

      span.rate {
        color: #999;
        text-overflow: ellipsis;
      }
    }

    .icon {
      font-size: 1.2rem;
      margin-left: 0.45rem;
    }

    &.price {
      display: flex;
      flex-direction: column;
      min-width: 20%;
      text-align: right;

      &.cash {
        & > span.price {
          font-weight: 500;
          color: #CD5E5E;
        }
      }

      &.bank_transfer {
        & > span.price {
          font-weight: 500;
          color: inherit;
        }
      }

      .text {
        color: #A5A5A5;
      }
    }
  }

  .policy__payments {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 12px 0;

    .policy__payment {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      .value {
        font-weight: 500;
      }

      .cash {
        color: #CD5E5E;
      }

      .description > span:first-child {
        text-transform: capitalize;
      }
    }

    .policy__payment + .policy__payment {
      margin-top: 12px;
    }
  }

  .policies__content {
    border-top: 1px solid #EAE9E4;
    border-bottom: 1px solid #EAE9E4;
    padding: 1rem 0;
  }

  a.action-icon {
    font-size: 1.2rem;
    color: inherit;
    line-height: 1;
    cursor: pointer;
  }

  &.divided {
    .policies__row + .policies__row {
      border-top: 1px solid #EAE9E4;
    }
  }

  &.selectable {
    .policies__row:not(.header) {
      &:hover { cursor: pointer; }
    }
  }
}
