.notifications {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 32rem;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 1rem;
  z-index: 99;

  &:empty {
    display: none;
  }

  .notification {
    flex-shrink: 0;
    background-color: #FFF;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.17);
    overflow: hidden;

    .content {
      width: 100%;
      height: 100%;
      padding: 1.5rem 0.5rem;
    }

    .header {
      position: relative;
      padding-left: 1rem;
      padding-right: 1rem + 0.875rem;
      font-size: 1.25rem;
      line-height: 1.5rem;
      vertical-align: middle;

      .action {
        position: absolute;
        right: 1rem;
        top: 0;
        height: 1.5rem;
        color: #333;
        line-height: 1.5rem;
        font-size: 1.25rem;
        vertical-align: middle;

        a {
          color: #333;
        }
      }
    }

    .description {
      max-height: 40vh;
      margin-top: 0.875rem;
      padding: 0 1rem;
      overflow: auto;

      p {
        margin: 0;
        color: #999999;
      }

      p + p {
        margin-top: 0.875rem;
      }
    }

    .actions {
      margin-top: 0.875rem;
    }
  }

  .notification + .notification {
    margin-top: 1rem;
  }

  .notification.success {
    .header { color: $color-success; }
  }

  .notification.error {
    .header { color: $color-error; }
  }

  .notification.warning {
    .header { color: $color-warning; }
  }
}
