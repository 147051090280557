.priceboard {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-left: $dash-ctrl-width;
  background-color: $dash-bg-color;
  z-index: 9;

  .priceboard__wrapper {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    overflow: auto;
  }
}
