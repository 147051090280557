.rates__age-period {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  .rates__age-period--item {
    label {
      display: inline-block;
      margin-right: 0.45rem;
    }

    input {
      max-width: 4rem;
    }

    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
    }
  }

  .rates__age-period--item + .rates__age-period--item {
    margin-left: 1rem;
  }
}
