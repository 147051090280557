.dashboard__portal {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content:  space-between;
  top: 0;
  left: 0;
  // left: -$dash-ctrl-width--right;
  width: calc(100% + #{$dash-ctrl-width--right});
  height: 100%;
  z-index: 9;

  &--header {
    height: 0;
    flex-grow: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &--content {
    position: relative;
    flex-grow: 0;
    height: 0;

    .quota-selector {
      position: absolute;
      border-left: 4px solid #4FA0FE;
      border-right: 4px solid #4FA0FE;
      border-top: 1px solid #4FA0FE;
      border-bottom: 1px solid #4FA0FE;
      border-radius: 4px;

      &__arrow {
        position: absolute;
        color: #4FA0FE;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.2rem;

        &.left {
          cursor: w-resize;
          left: 0;
          transform: translate(calc(-100% - 4px), -50%);
        }

        &.right {
          cursor: e-resize;
          right: 0;
          transform: translate(calc(100% + 4px), -50%);
        }

        .icon {
          margin: 0;
          svg { stroke-width: 1%; }
        }
      }
    }

    .quota-popover {
      position: absolute;
      min-width: 17rem;
      height: auto;
      background-color: #fff;
      border-radius: 4px;

      &.top {
        top: 0 !important;
        transform: translate(-100%, calc(-100% - 2.5rem));

        .arrow { display: none; }
      }

      &.bottom {
        transform: translate(-100%, 0.5rem);
      }

      .arrow {
        position: absolute;
        width: 0;
        height: 0;
        top: -5px;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
      }
    }
  }

  &--footer {
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
