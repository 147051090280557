.priceboard__content {
  width: 100%;
  height: calc(100% - #{$dash-ctrl-height});
  margin-top: $dash-ctrl-height;
  z-index: 8;

  .priceboard__content--wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .grid {
    position: absolute;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: auto;
    height: 100%;

    & > .item {
      flex-grow: 1;
      width: $dash-item-width;
      height: inherit;
      border-right: 1px solid #ccc;
      box-sizing: border-box;
      background-color: $dash-item-bg;

      &.day-off { background-color: $dash-item-bg-day-off; }
    }
  }

  .rows {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    min-width: 100%;
    min-height: 100%;

    & > .item {
      position: relative;

      .header {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        height: 3.375rem;
        background-color: #FFF;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
      }
    }

    .tariffs {
      padding: 0.75rem 0 1.5rem;
      border: none;

      .tariff {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 0.75rem;
        white-space: nowrap;

        &:first-child { margin-top: 0; }

        .tariff__header {
          height: $pd-tariff-header-h;
          margin-bottom: $pd-tariff-header-offset-bt;
          padding: 0 1.25rem;
          background-color: transparent;
          z-index: 2;
          text-align: left;
          font-size: 0.75rem;
        }
      }
    }
  }

  .tariff .rates {
    width: 100%;

    .rate {
      display: block;
      line-height: 1.5rem;
      height: 1.5rem;
      width: 100%;
      vertical-align: middle;
    }
  }

  .rates .rate__values {
    position: relative;
    width: auto;
    height: 1.5rem;
    line-height: 1.5rem;
    vertical-align: middle;
    white-space: nowrap;

    .rate__value {
      position: relative;
      display: inline-block;
      width: $dash-item-width;
      font-size: 0.75rem;
      text-align: center;

      .content {
        cursor: pointer;

        &.selected:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.43);
        }
      }
    }

    &.black,
    &.alone,
    &.single_occupation {
      .content { color: #333333; }
    }

    &.purpure,
    &.twins,
    &.double_occupation {
      .content { color: #D664CF; }
    }

    &.green,
    &.one_more,
    &.adult_on_extra_bed,
    &.adult_on_main_bed {
      .content { color: #58CA97; }
    }

    &.gray,
    &.baby,
    &.child_on_main_bed,
    &.child_on_extra_bed {
      .content { color: #C7C7C7; }
    }
  }
}
