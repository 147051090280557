.dashboard__control {
  .ctrl {
    position: absolute;
    top: 0;
    width: auto;
    box-sizing: content-box;
    z-index: 2;

    &.left {
      left: 0;
      width: $dash-ctrl-width--left;
      height: 100%;
      border-right: 1px solid #ccc;
    }

    &.right {
      right: 0;
      width: $dash-ctrl-width--right;
      border-left: 1px solid #ccc;
    }

    a {
      display: inline-block;
      width: 100%;
      height: $dash-ctrl-height;
      line-height: $dash-ctrl-height;
      text-align: center;
      vertical-align: middle;
      text-decoration: none;
      background-color: #FFF;
      color: #000;
      font-size: 2rem;

      &:hover {
        background-color: darken(#fff, 15%);
      }
    }
  }
}
