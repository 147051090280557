.photos {
  user-select: none;

  .photo {
    display: inline-block;
    position: relative;
    width: calc(#{percentage(1/4)} - 0.4rem);
    margin-top: 0.4rem;
    padding-top: calc(#{percentage(1/4)} - 0.4rem);
    border: 1px #d4d4d4 solid;
    box-sizing: border-box;
    overflow: hidden;
    cursor: move;
    border-radius: 4px;


    &:nth-child(2n+1) {
      margin-left: 0;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0.2rem solid #fff;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    img {
      width: 100%;
      height: auto;
    }

    .actions {
      visibility: hidden;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      text-align: center;
      transform: translateY(-50%);

      a {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        font-size: 1rem;
        line-height: 1.8rem;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 0.2rem;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }

      a + a {
        margin-left: 1px;
      }
    }

    &:hover {
      .actions { visibility: visible; }
    }

    .tag {
      background: #fff;
      font-size: 10px;
      font-weight: bold;
      position: absolute;
      z-index: 3;
      cursor: pointer;
      left: 10px;
      top: 10px;
      border-radius: 10px;
      padding: 0 5px;
      color: #000;
      height: 17px;
      line-height: 17px;
      white-space: nowrap;
      box-shadow: 0 0 5px rgba(0,0,0,0.5);

      &.disabled {
        opacity: 0.5;
      }
    }

    .other-tags {
      position: absolute;
      z-index: 3;
      top: 35px;
      left: 20px;
      display: none;
      flex-direction: column;

      &.opened {
        display: flex;
      }

      .tag {
        position: static;
        display: inline-block;
        margin-bottom: 5px;
        width: min-content;
      }
    }
    .alt_input{
      position: relative;
      z-index: 2;
      margin: 0 0.2rem 0.2rem 0.2rem;
      .alt_input_languages{
        z-index: 2;
        cursor: pointer!important;
      }
      .input{
        padding: 0 10px 0 25px;
      }
    }
  }

  .photo + .photo {
    margin-left: 0.4rem;
  }

  .photo:nth-child(4n+1) {
    margin-left: 0;
  }

  &.small {
    .photo {
      // width: calc(100% / 3 - 0.4rem);
      // padding: 0.15rem;
      // margin: 0.2rem;
    }
  }
}
