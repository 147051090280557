// Clearfix
@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

// Transition
@mixin transition($transition: all linear .2s) {
    -moz-transition: $transition;
    transition: $transition;
}

// transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// blur
@mixin blur($radius) {
    -webkit-filter: blur($radius);
    -moz-filter: blur($radius);
    -ms-filter: blur($radius);
    filter: blur($radius);
}

// striped
@mixin striped($color: rgba(255, 255, 255, .2), $angle: 45deg) {
    background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}

// animation
@mixin animation($animation) {
    -webkit-animation: $animation;
    -moz-animation: $animation;
    animation: $animation;
}


// Retina Images
@mixin retina-background-image($file, $type, $width:  auto, $height:  auto) {

    background-repeat: no-repeat;
    background-image: url($file + '.' + $type);

    @media
      only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min--moz-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2/1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx) {
        background-image: url($file + '-2x.' + $type);
        background-size: $width $height;
    }
}