@import 'variables';

.DayPicker {
  background: $react-dates-color-white;
  position: relative;
  text-align: left;
}

.DayPicker--horizontal {
  background: $react-dates-color-white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);

  &.DayPicker--portal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%;
  }
}

.DayPicker--vertical.DayPicker--portal {
  position: initial;
}

// we can revisit this styling during the a11y picker
.DayPicker__focus-region {
  outline: none;
}

.DayPicker__week-headers {
  position: relative;
}

.DayPicker--horizontal .DayPicker__week-headers {
  margin-left: 9px;
}

.DayPicker__week-header {
  color: $react-dates-color-placeholder-text;
  position: absolute;
  top: 62px;
  z-index: $react-dates-z-index + 2;
  padding: 0 13px;
  text-align: left;

  ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
  }

  li {
    display: inline-block;
    text-align: center;
  }
}

.DayPicker--vertical .DayPicker__week-header {
  left: 50%;
}

.DayPicker--vertical-scrollable {
  height: 100%;

  .DayPicker__week-header {
    top: 0;
    display: table-row;
    border-bottom: 1px solid $react-dates-color-border;
    background: white;
  }

  .transition-container--vertical {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll;
  }

  .DayPicker__week-header {
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
}

.transition-container {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.transition-container--horizontal {
  transition: height 0.2s ease-in-out;
}

.transition-container--vertical {
  width: 100%;
}
