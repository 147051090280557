label {
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 0.25rem;
}

table {
  font-size: inherit;
  td, th, tr { border: none; }
}

.react-tiny-popover-container {
  border: 1px solid silver;
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  border-radius: 3px;
  padding: 10px;
  min-width: 160px;
  z-index: 10;
}