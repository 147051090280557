.groups {
  position: relative;
  width: 100%;
}

.groups__item {
  margin-bottom: 2rem;
}

.groups__item_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .showAll {
    color: #1F6FCC;
    cursor: pointer;
  }
}

.groups__item-header {
  font-size: 1.25rem;
  padding: 1rem 0;
}

.groups__item-content {
  width: auto;
  height: auto;
  background-color: #FFF;
}

.groups__item-block {
  padding: 1.875rem;

  & > .header {
    font-size: 1.25rem;
    line-height: 1.5rem;
    vertical-align: middle;
  }

  & > .content {
    position: relative;
  }

  .header + .content {
    margin-top: 1.25rem;
  }

  .content > .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 10;
  }
}

.groups__item-relative {
  padding: 1.875rem;
}

.groups__item-blocks {
  @include flex;
  @include flex-items-column;

  .groups__item-block + .groups__item-block {
    // border-top: 1px solid #DCD8D2;
    // padding-top: 0;
  }
}
