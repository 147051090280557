.checkbox__fields {
  .checkbox__field + .checkbox__field {
    margin-top: 1rem;
  }
}

.rc_checkbox__field {
  padding: 0.875rem !important;
}

.rc-tree-child-tree-open {
  li {
    padding: 0.875rem !important;
  }
}

.checkbox__field {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  user-select: none;

  .key {
    margin-right: 1rem;
  }

  .value {

  }
}
