// Kube
@import "base/variables";
@import "kube/kube";
@import "~loaders.css/loaders.min.css";

// Base
@import 'base/reset';
@import 'base/base';

// RC Slider
@import '~rc-slider/assets/index.css';

// Phone Input
@import '~react-phone-input-2/lib/style.css';
@import 'components/phone-input-2/default';

// Components
@import 'components/infinity-menu/default';
@import 'components/topbar/default';
@import 'components/buttons/default';
@import 'components/collapse/default';
@import 'components/page/default';
@import 'components/groups/default';
@import 'components/rooms/default';
@import 'components/room_types/default';
@import 'components/buildings/default';
@import 'components/options/default';
@import 'components/dashboard/default';
@import 'components/priceboard/default';
@import 'components/marker/default';
@import 'components/stepper/default';
@import 'components/segment/default';
@import 'components/form/default';
@import 'components/select/default';
@import 'components/table/default';
@import 'components/tariffs/default';
@import 'components/price_lists/default';
@import 'components/price-block/default';
@import 'components/date-picker/default';
@import 'components/photos/default';
@import 'components/loader/default';
@import 'components/periods/default';
@import 'components/stay-period/default';
@import 'components/price-calculation-rule/default';
@import 'components/seasons/default';
@import 'components/quotas/default';
@import 'components/orders/default';
@import 'components/topbar/default';
@import 'components/reservation-group/default';
@import 'components/policies/default';
@import 'components/status/default';
@import 'components/rates/default';
@import 'components/bank_cards/default';
@import 'components/rc-tree/default';
@import 'components/conversations/default';
@import 'components/collapsible/default';
@import 'components/infoblock/default';
@import 'components/notifications/default';
@import 'components/video-popover/default';
@import 'components/popover/_default';

// Pages
@import 'pages/layout/layout';
@import 'pages/login/login';


.selectable-group {
  position: relative;
  overflow: hidden;

  .group {
    width: 100%;
    margin-bottom: 4rem;
  }

  .item {
    float: left;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border: 1px solid blue;

    &.selected {
      background-color: blue;
    }
  }
}

.age_input_from {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.age_input_to {
  border-left: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rc-slider-dot {
  display: none;
}

.rc-slider-mark {
  top: 9px !important;
}

.rc-slider-handle {
  z-index: 5;
}

.extra_option {
  width: 250px;
}
