.sc-quota-box {
  position: absolute;
  z-index: 9000;
  cursor: default;
}

.sc-quota-selector {
  position: absolute;
  // float: left;
  width: 100%;
  height: 100%;
  border-left: 4px solid #4FA0FE;
  border-right: 4px solid #4FA0FE;
  border-top: 1px solid #4FA0FE;
  border-bottom: 1px solid #4FA0FE;
  border-radius: 4px;

  &__arrow {
    position: absolute;
    color: #4FA0FE;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2rem;
    width: 15px;

    &.left {
      cursor: w-resize;
      left: 0;
      transform: translate(calc(-100% - 4px), -50%);
    }

    &.right {
      cursor: e-resize;
      right: 0;
      transform: translate(calc(100% + 4px), -50%);
    }

    .icon {
      margin: 0;
      pointer-events: none;
      svg { stroke-width: 1%; }
    }
  }
}
