@import 'dropzone';
@import 'map';
@import 'checkbox';
@import 'toggle';
@import 'quotas-form';

textarea { resize: vertical; }

form {
  margin-bottom: 0;
}

form.size400 {
  width: 25rem;
}

form .form-item {
  margin-bottom: 1rem;
  &:last-child { margin-bottom: 0rem; }
}

form .form-buttons {
  padding-top: 1rem;
}

.form {
  position: relative;
  margin: 0;

  .form__section {
    .header {
      font-size: 0.975rem;
      line-height: 2.225rem;
    }

    .content {

    }

    .footer {

    }

    .content + .footer {
      margin-top: 16px;
    }
  }

  .form__section + .form__section {
    margin-top: 1rem;
  }

  &.equal {
    .form__body > .form__fields:not(:first-child) label {
      display: none;
    }

    .form__body > .form__fields-group:not(:first-child) .form__fields:first-child label {
      display: none;
    }
  }

  .form__body {
    width: inherit;
    height: inherit;

    .row + .row {
      margin-top: 1.125rem;
    }
  }

  .form__fields-wrapper {
    margin-bottom: 3.125rem;
    &:last-child { margin-bottom: 0; }
  }

  .form__control {
    margin-top: 2.5rem;

    button, .button {
      margin-right: 0.75rem;
    }
  }

  .form__fields-group {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
  }

  .form__fields-group + .form__fields-group {
    margin-top: 1.875rem;
  }

  .form__fields {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items:  flex-end;
    margin-bottom: 1rem;
    // width: 25%;

    .form__field {
      flex-grow: 0;
      margin-right: 0.75rem;
      margin-bottom: 0rem;
      &:last-child { margin-right: 0rem; }
      &.grow { flex-grow: 1; }
    }

    &:last-child { margin-bottom: 0; }

    &.deleted { display: none; }
  }

  .form__field {
    position: relative;
    margin-bottom: 1rem;
    user-select: none;

    &:last-child {
      margin-bottom: 0rem;
    }

    .field__header {
      position: relative;
      font-size: 0.875rem;

      .action {
        position: absolute;
        top: 0;
        right: 0;
      }
    }


    .form__field-number {
      display: inline-block;
      position: absolute;
      left: -1rem;
      bottom: calc(0.5rem - 1px);
      color: #838383;
      vertical-align: bottom;
    }

    .form__field-action {
      display: inline-block;
      width: 1.75rem;
      height: 2rem;
      margin: 0;
      line-height: 2rem;
      text-align: center;
      vertical-align: middle;
      text-decoration: none;
    }

    .form__field-promt {
      margin-top: 0.5rem;
      color: #999999;
    }

    &.slider {
      label { margin-bottom: 0.425rem; }
    }
  }

  .form__field.inline {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    &.price > input {
      width: 7rem;
      margin-right: 0.5rem;
    }

    &.tight {
      margin-bottom: 0.25rem;
      &:last-child { margin-bottom: 0; }
    }

    label {
      flex-grow: 1;
      margin-bottom: 0;
    }

    input {
      flex-grow: 0;
      margin-right: 0.875rem;
    }
  }

  .form__field.checkbox {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    input {
      margin-top: 1px;
      margin-right: 8px;
    }
  }

  .form__field.empty {
    margin-bottom: 0;
  }

  .form__field.action {
    margin-top: 1.25rem;
  }

  .form__field, .form__body {
    @for $i from 1 through 100 {
      &.w#{$i} { width: $i * 1%;  min-width: $i * 1%; }
    }
  }

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.form__field.manipulation_option {
  position: relative;

  .number {
    position: absolute;
    left: -1rem;
    color: #838383;
    top: 50%;
    transform: translateY(-50%);
  }

  .action {
    position: absolute;
    right: -18px;
    top: calc(50% + 2px);
    color: #838383;
    transform: translateY(-50%);
  }
}
