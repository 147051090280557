@import "./select";
@import "~react-select/scss/control";
@import "~react-select/scss/menu";
@import "~react-select/scss/mixins";
@import "~react-select/scss/multi";
@import "~react-select/scss/spinner";

.Select-control {
  position: relative;
  border: none;
}

.is-open > .Select-control {
  border: none;
}

.Select-menu-outer {
  border: none;
}

.Select-value {
  padding-right: 25px !important;
}

.Select-input > input {
  padding: 0;
}

.Select.error {
  .Select-control {
    background-color: rgba(255, 86, 86, .1);
    border: 1px solid #ffa3a3;
  }
}
