table.table {
  position: relative;
  width: 100%;
  margin: 0;

  tr {
    position: relative;

    &.deleted { display: none; }
  }

  td, th {
    border-right: 1rem solid transparent;
    vertical-align: top;

    &:last-child { border-right: none; }

    &.align-left { text-align: left; }
    &.align-right { text-align: right; }
    &.align-center { text-align: center; }
  }

  thead {
    th {
      cursor: auto;
      padding: 0;
      padding-bottom: 1rem;
      text-align: left;
      color: #999999;
      vertical-align: middle;
      font-weight: normal;
      border-bottom: 1px solid #D7D3CC;

    }

    &.tight {
      th { padding-bottom: 0.5rem; }
    }
  }

  tbody {
    td {
      padding: 0.5rem 0;
      border-bottom: none;
    }

    tr:first-child td {
      padding-top: 1rem;
    }

    tr:last-child td {
      padding-bottom: 0rem;
    }

    span.number {
      position: absolute;
      left: -1rem;
      color: #838383;
    }
  }

  &.divided {
    tbody td {
      padding: 0.425rem 0;
      border-bottom: 1px solid #D7D3CC;

      &-icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
        height: 1.8rem;
      }
    }

    tr:last-child td {
      padding-bottom: 1rem;
      border-bottom: none;
    }
  }

  &.table:first-child {
    margin-top: 0rem;
  }

  &.table:last-child {
    margin-bottom: 0rem;
  }

  a.action__icon {
    margin-right: 1rem;
    font-size: 1.2rem;
    color: inherit;
  }
}

