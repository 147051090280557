.reservation-group {
  .reservation-group__header {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }

  .reservation-group__content {
    .section + .section {
      margin-top: 1.5rem;
    }

    .section {
      .section__caption {
        font-size: 1.25rem;
        line-height: 1.5rem;
        margin-bottom: 1rem;

        .sub {
          font-size: 1rem;
        }
      }

      .section__header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
        cursor: pointer;
        user-select: none;
        margin-bottom: 1rem;
        line-height: 1.25rem;
        font-weight: bold;

        .price {
          flex-shrink: 0;
          font-size: 1rem;
        }

        .reservation-status {
          display: block;
          font-size: 14px;
          font-weight: 500;
        }

        .sub {
          font-weight: normal;
        }
      }

      &.divided {
        padding-bottom: 1rem;
        border-bottom: 1px solid #ddd;
      }
    }
  }

  & + .reservation-group {
    margin-top: 1.5rem;
  }
}
