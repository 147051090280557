.video-popover {
  position: relative;
  height: 100%;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.8);

  & > .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);


    & > .action_close {
      float: right;
      margin-bottom: 0.5rem;

      color: #fff;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
}