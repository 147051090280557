@import "age__period";

.rates {
  display: relative;
  width: 100%;

  .rates__header {
    width: 100%;
    color: #999;
    margin-bottom: 1rem;

    &.bordered {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #D7D3CC;
    }
  }

  .rates__list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;

    & > .rates__item {
      position: relative;
      width: 100%;
      height: auto;

      &.deleted {
        display: none;
      }
    }

    .rates__item + .rates__item {
      // margin-top: 0.875rem;
      border-top: 1px solid #D7D3CC;
    }
  }

  .rates__item > .content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    .item {
      line-height: 2.4rem;
      vertical-align: middle;

      &.description {
        flex-grow: 1;
      }

      &.price {
        min-width: 4rem;
      }

      &.action {
        width: 2rem;
        font-size: 1rem;
        text-align: right;

        a { color: inherit; }
      }
    }
  }

  .rates__item > .control {
    position: relative;
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid #D7D3CC;
  }

  .rates__empty {
    margin-top: 16px;
    p { color: #ff9595; }
  }

  .rates__form {
    .rates__form--body + .rates__form--control {
      margin-top: 1.75rem;
    }
  }

  .rates__list + .rates__form {
    margin-top: 1.75rem;
  }
}
