.field__dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #999;
  margin-bottom: 1rem;
  box-sizing: border-box;
  @include transition(all .1s ease-in-out);

  &:not(.compact) {
    border: 2px dashed #DCD8D2;
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    padding: 1rem;
  }

  &.compact {
    p { display: none; }
    button, .button { width: 100%; }
  }

  .content {
    width: 100%;
    text-align: center;
  }

  &--active {
    box-shadow: inset 0 0 1rem #dedede;
  }

  &--rejected {
    border-color: #FF0000;
  }
}

.field__dropzone__room-type {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #999;
  margin-bottom: 1rem;
  box-sizing: border-box;
  @include transition(all .1s ease-in-out);

  &:not(.compact) {
    border: 2px dashed #1F6FCC;
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background: rgba(31, 111, 204, 0.04);
  }

  &.compact {
    p { display: none; }
    button, .button { width: 100%; }
  }

  .content {
    width: 100%;
    text-align: center;
  }

  &--active {
    box-shadow: inset 0 0 1rem #dedede;
  }

  &--rejected {
    border-color: #FF0000;
  }
}
