$room_types-item-size: 3.5rem;
$room_types-item-border: 1px solid #D1D1D1;
$room_types-item-bg-active: #3B4149;
$room_types-item-shadow: inset 0 1px 7px 0 rgba(0,0,0,0.50);
$room_types-item-font-active: #FFF;

.room_types {
  position: relative;
  overflow: auto;
}

.room_types__item {
  position: relative;
  width: 100%;
  height: $room_types-item-size;
  box-sizing: border-box;
  overflow: hidden;

  & > .caption {
    width: 100%;
    height: inherit;

    a {
      display: inline-block;
      width: 100%;
      height: inherit;
      line-height: $room_types-item-size;
      color: inherit;
      text-decoration: none;
      white-space: nowrap;
      padding: 0 1rem;
      overflow: hidden;
      text-overflow: ellipsis;

      &.active, &:hover {
        color: $room_types-item-font-active;
        background-color: $room_types-item-bg-active;
        box-shadow: $room_types-item-shadow;
      }
    }
  }

  & > .content {
    padding: 0.875rem 1.875rem;

    .actions {
      padding: 1.875rem 0 1rem;
      overflow: auto;
    }
  }
}

.room_types__item + .room_types__item {
  border-top: $room_types-item-border;
}
