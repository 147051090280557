@import '~rc-tree/assets/index.css';

.rc-tree {
  padding: 0;

  li span.rc-tree-checkbox {
    width: 0.875rem;
    height: 0.875rem;
    margin-left: 0.2rem;
    margin-right: 0.4rem;
  }

  .rc-tree-node-content-wrapper {
    max-width: calc(100% - 2rem);
    white-space: normal;
    height: auto !important;
  }

  .rc-tree-node-selected {
    background-color: inherit;
    border: none;
    opacity: 1;
    font-weight: bold;
  }

  li {
    overflow: hidden;
    user-select: none;
    padding: 0.435rem 0 0 0 !important;
  }

  li ul {
    margin: 0;
    padding: 0 0 0.435rem 0.875rem;
  }
}
